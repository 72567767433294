import videojs from 'video.js';

function initVideo(videoId, autoplay=false) {
  if (!videoId) return;

  const defaultOptions = {
    autoplay,
    controls: true,
    fill: true,
    preload: 'auto',
    playbackRates: [1, 1.5, 2],
    html5: {
      nativeTextTracks: false,
    },
  };

  const video = document.getElementById(videoId);
  const poster = video.getAttribute('data-vjs-thumbnail');
  const duration = video.getAttribute('data-vjs-duration');


  const options = {
    ...defaultOptions,
    ...(poster ? { poster } : {}),
  };

  const player = videojs(video.id, options, function() {
    player.src(video.getAttribute('data-vjs-src'));
    player.focus();

    this.children_.filter(c => (c.name_ === 'PosterImage' || c.name_ === 'BigPlayButton'));

    this.controlBar.playToggle.on(['click', 'tap'], () => {
      videojs.getAllPlayers().filter(p => p.id_ !== this.id_).forEach(p => p.pause());
    });

    videojs.getAllPlayers().forEach(p => p.pause());
  })


  if (duration) {
    const durationEle = document.createElement('div');
    durationEle.classList.add('video-js__duration');
    durationEle.innerText = duration;
    video.parentElement?.appendChild(durationEle);
  }
}

function disposeVideo(videoId) {
  if (!videoId) return;

  const video = document.getElementById(videoId);
  if (!!video) window.videojs(video).dispose();
}

function disposeVideoAndRevertUrl(videoId) {
  history.back();

  if (!videoId) return;

  const video = document.getElementById(videoId);
  if (!!video) window.videojs(video).dispose();
}

function disposeAllVideos() {
  const videos = Array.from(document.body.querySelectorAll('.video-js:not(.video-js-for-style)'));
  for (let video of videos) {
    if (!!video) window.videojs(video).dispose();
  }
}

function setupVideoInstances() {
  const defaultOptions = {
    autoplay: false,
    controls: true,
    fill: true,
    preload: 'auto',
    playbackRates: [1, 1.5, 2],
    html5: {
      nativeTextTracks: false,
    },
  };

  const videos = Array.from(document.body.querySelectorAll('.video-js:not(.video-js-for-style)'));

  for (let video of videos) {
    const poster = video.getAttribute('data-vjs-thumbnail');
    const duration = video.getAttribute('data-vjs-duration');

    const options = {
      ...defaultOptions,
      ...(poster ? { poster } : {}),
    };

    const player = videojs(video.id, options, function() {
      const elements = this.children_.filter(c => (c.name_ === 'PosterImage' || c.name_ === 'BigPlayButton'));

      this.controlBar.playToggle.on(['click', 'tap'], () => {
        videojs.getAllPlayers().filter(p => p.id_ !== this.id_).forEach(p => p.pause());
      });

      elements.forEach(ele => ele.on(['click', 'tap'], () => {
        videojs.getAllPlayers().forEach(p => p.pause());
        player.src(video.getAttribute('data-vjs-src'));
      }));
    });

    if (duration) {
      const durationEle = document.createElement('div');
      durationEle.classList.add('video-js__duration');
      durationEle.innerText = duration;
      video.parentElement?.appendChild(durationEle);
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (!!document.querySelector('body.top_shop_profiles.show') || !!document.querySelector('body.wrench-way') || !!document.querySelector('body.is-subdomain.show_shop_talk_post')) {
    setupVideoInstances();
  }
});

window.videojs = videojs;
window.initVideo = initVideo;
window.disposeVideo = disposeVideo;
window.disposeAllVideos = disposeAllVideos;
window.disposeVideoAndRevertUrl = disposeVideoAndRevertUrl;
window.setupVideoInstances = setupVideoInstances;
