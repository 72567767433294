try {
require("@rails/ujs").start()
}catch(e){
}
require("../detect-ie")
require("../topshops/gmaps")
require("../topshops/infinite-scroll")
require("../utilities")
require("../presentable")
require("../present-tooltip")
require("../toast.js")
require("../videojs")
require("../topshops/carousel")
require("../listbox")
require("../ajax")
require("../strip-form")
