(function () {
  let map = null;
  let currentMapMarkers = [];
  let googleMarkers = {};
  let infoWindow;
  let initializedPrompt = false;

  if (document.referrer.indexOf(window.location.host) === -1) {
    localStorage.setItem('scrollpos', 0);
  }

  const initalizeMapRefresh = () => {
    let center = null;
    let radius = null;

    const mapSearchForm = document.getElementById('wrenchway-map-search-form');
    const refreshButton = document.getElementById('map-search-area-prompt');

    if (refreshButton) {
      refreshButton.addEventListener('click', e => {
        e.target.classList.add('sr-only');

        if (mapSearchForm) {
          mapSearchForm.lat.value = center.lat();
          mapSearchForm.lng.value = center.lng();
          mapSearchForm.radius.value = radius || 1;
        }

        if (radius > 1) {
          const radiusSelect = document.getElementById('radius');
          const radiusValues = [5, 10, 25, 50, 75, 100, 250];
          if (radiusValues.length === 8) {
            if (![5, 10, 25, 50, 75, 100, 250].includes(radius)) {
              const opt = document.createElement('option');
              opt.value = radius;
              opt.innerHTML = `${radius} miles`;
              document.getElementById('radius_select').appendChild(opt);
              radiusSelect.value = radius;
            }
          } else {
            const r = radiusValues[radiusValues.length - 1];
            r.value = radius;
            r.innerHTML = `${radius} miles`;
            radiusSelect.value = radius;
          }
        }

        const centerLat = center.lat();
        const centerLng = center.lng();
        const centerLatLng = `${centerLat},${centerLng}`;

        const newUrl = `${document.location.pathname}?query=${centerLatLng}&lat=${centerLat}&lng=${centerLng}&radius=${radius || 1}${mapSearchForm.shop_type?.value ? `&shop_type=${mapSearchForm.shop_type.value}` : ''}`;
        window.location.replace(newUrl)
        // axios({
        //   headers: {
        //     'Accept': 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01',
        //     'X-Requested-With': 'XMLHttpRequest',
        //     'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').getAttribute('content'),
        //   },
        //   url: newUrl,
        //   method: 'get',
        // }).then((response) => {
        //   const script = document.createElement('script');
        //   script.innerHTML = response.data;
        //   document.body.appendChild(script);

        //   window.history.replaceState(null, '', newUrl);
        // });
      });
    }

    function promptNewSearch() {
      if (!initializedPrompt) {
        initializedPrompt = true;
        return;
      }

      if (!!refreshButton) refreshButton.classList.remove('sr-only');

      const mapBounds = map.getBounds();
      const mapCenter = map.getCenter();
      if (mapBounds && mapCenter) {
        const ne = mapBounds.getNorthEast();
        const radiusInMeters = google.maps.geometry.spherical.computeDistanceBetween(mapCenter, ne);
        const radiusInMiles = Math.ceil(radiusInMeters * 0.000621371192);

        center = mapCenter;
        radius = radiusInMiles || 1;
        initializedPrompt = false;
      }
    }
    map.addListener('dragend', promptNewSearch);
    map.addListener('zoom_changed', promptNewSearch);
  }

  window.initializeMapMarkers = (markers, expand, zoomLevel, originalLocation) => {
    currentMapMarkers = {};
    const latlngbounds = new google.maps.LatLngBounds();

    const refreshButton = document.getElementById('map-search-area-prompt');

    if (zoomLevel) {
      map.setZoom(zoomLevel);
    }

    if (originalLocation && originalLocation.lat && originalLocation.lng) {
      const originalLatLng = new google.maps.LatLng(originalLocation.lat, originalLocation.lng);
      latlngbounds.extend(originalLatLng);
    }

    markers.forEach((data) => {
      const ownerId = data.shopId || data.schoolId;

      if (!!googleMarkers[ownerId]) {
        if (expand) latlngbounds.extend(googleMarkers[ownerId].position);
        return;
      }

      const position = new google.maps.LatLng(data.lat, data.lng);
      const marker = new google.maps.Marker({
        position,
        title: data.title,
        map
      });

      currentMapMarkers = {
        ...googleMarkers,
        [ownerId]: marker,
      };
      googleMarkers = {
        ...googleMarkers,
        [ownerId]: marker,
      };

      (function (marker, data) {
        google.maps.event.addListener(marker, 'click', () => {
          if (data.shopId) {
            infoWindow.setContent(`
              <div class="my-1 mx-1 flex-1">
                <p class="text-sm font-medium text-gray-900">${data.title}</p>
                <p class="mt-1 text-sm text-secondary"><a href="/shops/${data.shopId}" target="_blank" alt="${data.title} profile" class="outline-none">view shop</a></p>
              </div>
            `);
          } else {
            infoWindow.setContent(`
              <div class="my-1 mx-1 flex-1">
                <p class="text-sm font-medium text-gray-900">${data.title}</p>
                <p class="mt-1 text-sm text-secondary"><a href="/schools/${data.schoolId}" target="_blank" alt="${data.title} profile" class="outline-none">view school</a></p>
              </div>
            `);
          }
          infoWindow.open(map, marker);
        });
      })(marker, data);

      if (expand) latlngbounds.extend(marker.position);
    });

    if (expand && (markers.length > 0 || originalLocation)) {
      map.setCenter(latlngbounds.getCenter());

      if (markers.length === 1 && !originalLocation) {
        map.setZoom(zoomLevel || 13);
      } else {
        map.fitBounds(latlngbounds);

        if (zoomLevel) {
          google.maps.event.addListenerOnce(map, 'bounds_changed', function() {
            this.setZoom(Math.min(this.getZoom(), zoomLevel));
            refreshButton.classList.add('sr-only');
          });
        }
      }
    }
  }

  window.centerOnMarkers = () => {
    const latlngbounds = new google.maps.LatLngBounds();

    for (let markerId in currentMapMarkers) {
      latlngbounds.extend(currentMapMarkers[markerId].position);
    }

    const mapMarkerKeys = Object.keys(currentMapMarkers);
    if (mapMarkerKeys && mapMarkerKeys.length > 0) {
      map.setCenter(latlngbounds.getCenter());

      if (mapMarkerKeys.length === 1) {
        map.setZoom(15);
      } else {
        map.fitBounds(latlngbounds);
      }
    }
  }

  window.centerMap = (lat, lng, radius) => {
    const radiusMeters = (radius - 4) * 1609.34;
    const circle = new google.maps.Circle({
      center: new google.maps.LatLng(lat, lng),
      radius: radiusMeters,
      fillOpacity: 0,
      strokeOpacity: 0,
      map,
    });
    map.fitBounds(circle.getBounds(), 0);
  }

  window.initMap = () => {
    infoWindow = new google.maps.InfoWindow();

    const eleMap = document.getElementById('map');
    const zoomLevel = parseInt(eleMap.getAttribute('data-zoom-level'), 10) || null;
    const defaultLat = parseFloat(eleMap.getAttribute('data-default-lat')) || 43.071202;
    const defaultLng = parseFloat(eleMap.getAttribute('data-default-lng')) || -89.401141;
    const markers = JSON.parse(eleMap.getAttribute('data-markers')) || [];
    const mapStyles = [
      {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [ { "visibility": "off" } ]
      },
      {
        "featureType": "poi",
        "stylers": [ { "visibility": "off" } ]
      },
      {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [ { "visibility": "off" } ]
      },
      {
        "featureType": "transit",
        "stylers": [ { "visibility": "off" } ]
      }
    ];

    if (markers.length === 0) {
      map = new google.maps.Map(eleMap, {
        center: new google.maps.LatLng(defaultLat, defaultLng),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        zoomControl: true,
        draggable: true,
        scrollwheel: false,
        disableDoubleClickZoom: false,
        styles: mapStyles,
        ...(zoomLevel ? { zoom: zoomLevel } : { zoom: 11 }),
      });

      initalizeMapRefresh();
      return false;
    }

    map = new google.maps.Map(eleMap, {
      center: new google.maps.LatLng(markers[0].lat, markers[0].lng),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      zoomControl: true,
      draggable: true,
      scrollwheel: false,
      disableDoubleClickZoom: false,
      styles: mapStyles,
      ...(zoomLevel ? { zoom: zoomLevel } : {}),
    });

    initializeMapMarkers(markers, true, zoomLevel, { lat: defaultLat, lng: defaultLng });
    initalizeMapRefresh();
    return true;
  }

  window.clickMarker = (shopId) => {
    const marker = googleMarkers[shopId];
    if (marker) { new google.maps.event.trigger(marker, 'click') }
  }

  window.hideMarkerLabel = () => {
    if (!!infoWindow) infoWindow.close();
  }
})();
