document.addEventListener('DOMContentLoaded', () => {
  const currentSelector = 'x-current';
  const selectedSelector = 'x-selected';

  /* private methods */
  const presentListbox = (list, button) => {
    list.classList.remove('hidden');
    button.setAttribute('aria-expanded', 'true');
  }

  const dismissListbox = (list, button) => {
    list.style.transition = 'ease-in 100ms';
    list.style.opacity = '0';
    button.setAttribute('aria-expanded', 'false');
  }

  const toggleListbox = ({ list, button }) => () => {
    if (button.getAttribute('aria-expanded') === 'true') {
      dismissListbox(list, button);
    } else {
      presentListbox(list, button);
    }
  }

  const setPlaceholder = (name) => {
    if (name.innerText === '') {
      name.innerHTML = `<span class="text-gray-400">${name.dataset.placeholder}</span>`;
    }
  }

  const handleListboxKeyDown = ({ root, button, list }) => (e) => {
    const current = root.querySelector('.'+currentSelector) ?? null;

    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();
        if (!!current) current.classList.remove(currentSelector);
        const next = current?.nextElementSibling ?? list.firstElementChild;
        next.classList.add(currentSelector);
        next.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        next.focus();
      } break;

      case 'ArrowUp': {
        e.preventDefault();
        if (!!current) current.classList.remove(currentSelector);
        const prev = current?.previousElementSibling ?? list.lastElementChild;
        prev.classList.add(currentSelector);
        prev.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        prev.focus();
      } break;

      case ' ': // spacebar
      case 'Enter': {
        e.preventDefault();
        if (list.classList.contains('hidden')) {
          presentListbox(list, button);
        } else if (!!current) {
          current.click();
        }
      } break;

      case 'Escape': {
        e.preventDefault();
        e.stopPropagation();
        dismissListbox(list, button);
      } break;
    }
  }

  const handleListboxOptionClick = ({ input, name, list, button }) => (e) => {
    if (!e.target.classList.contains('x-listbox-item')) return;

    if (e.target.classList.contains(selectedSelector)) {
      dismissListbox(list, button);
      return;
    }

    input.value = e.target.dataset.value;
    name.innerText = e.target.dataset.label;

    list.querySelectorAll('.x-listbox-item').forEach((option) => {
      option.classList.remove(currentSelector);

      if (option.dataset.value === e.target.dataset.value) {
        e.target.classList.add(selectedSelector);
      } else {
        option.classList.remove(selectedSelector);
      }
    });

    dismissListbox(list, button);

    input.dispatchEvent(new Event('change'));
  }

  const handleListboxMultiOptionClick = ({ input, name, button }) => (e) => {
    if (!e.target.classList.contains('x-listbox-item')) return;

    const inputDelimiter = ',';
    const nameDelimiter = ', ';

    const inputValue = input.value;
    const inputValueList = !!inputValue ? inputValue.split(inputDelimiter) : [];

    const nameText = name.innerText;
    const nameTextList = !!nameText ? nameText.split(nameDelimiter) : [];

    const isSelected = e.target.classList.contains(selectedSelector);

    if (isSelected) {
      e.target.classList.remove(selectedSelector);
      input.value = inputValueList.filter(v => v !== e.target.dataset.value).join(inputDelimiter);
      name.innerText = nameTextList.filter(v => v !== e.target.dataset.label && v !== name.dataset.placeholder).join(nameDelimiter);
    } else {
      e.target.classList.add(selectedSelector);
      input.value = inputValueList.concat(e.target.dataset.value).join(inputDelimiter);
      name.innerText = nameTextList.concat(e.target.dataset.label).filter(v => v !== name.dataset.placeholder).join(nameDelimiter);
    }

    setPlaceholder(name);

    // button.focus();
    input.dispatchEvent(new Event('change'));
  }

  /* initialize */
  function initializeListbox(id) {
    /* static elements */
    const root = document.getElementById(id);               // containing element
    const input = root.querySelector('.x-listbox-input');   // hidden input for form submission
    const label = root.querySelector('.x-listbox-label');   // label tag
    const button = root.querySelector('.x-listbox-button'); // appears to look like a typical select input but is a button
    const name = root.querySelector('.x-listbox-name');     // text of button for selected value
    const list = root.querySelector('.x-listbox-list');     // list of option item buttons

    const elements = { root, input, label, name, button, list };

    list.addEventListener('transitionend', () => {
      list.classList.add('hidden');
      list.style = null;
    });
    list.addEventListener('transitioncancel', () => {
      list.style = null;
    });
    button.addEventListener('blur', (e) => {
      if (!root.contains(e.relatedTarget) && button.getAttribute('aria-expanded') === 'true') {
        button.setAttribute('aria-expanded', 'false');
        dismissListbox(list, button);
      }
    });

    label.addEventListener('click', () => button.focus());
    button.addEventListener('click', toggleListbox(elements));
    button.addEventListener('keydown', handleListboxKeyDown(elements));
    list.addEventListener('mousedown', e => e.preventDefault());
    list.addEventListener('click', (root.dataset.multiple === 'true' ? handleListboxMultiOptionClick : handleListboxOptionClick)(elements));

    setPlaceholder(name);
  }

  function initializeAllListboxes() {
    document.querySelectorAll('.x-listbox').forEach(ele => initializeListbox(ele.id));
  }
  initializeAllListboxes();

  /* public methods */
  window.initializeListbox = initializeListbox;
  window.initializeAllListboxes = initializeAllListboxes;
});
