let topShopProfileTriggeredHash = {};

document.addEventListener('DOMContentLoaded', () => {
  const infiniteLoader = document.querySelector('[data-infinite-loader]');

  if (infiniteLoader) {
    let debouncing = false;
    const listEle = document.getElementById(infiniteLoader.getAttribute('data-infinite-loader'));

    const infiniteHandler = () => {
      const isMobile = window.matchMedia("only screen and (max-width: 639px)").matches;
      const deviceHeight = document.documentElement.clientHeight;

      if (
        !debouncing &&
        (isMobile && listEle.getBoundingClientRect().bottom <= (deviceHeight + 200))
        || (!isMobile && ((listEle.scrollTop + listEle.clientHeight) >= (listEle.scrollHeight * 0.85)))
      ) {
        const nextLink = document.querySelector('[data-infinite-loader] a');
        if (nextLink && !topShopProfileTriggeredHash[nextLink.href]) {
          topShopProfileTriggeredHash = {
            ...topShopProfileTriggeredHash,
            [nextLink.href]: true,
          };
          nextLink.click();
        }

        debouncing = true;
        setTimeout(() => debouncing = false, 500);
      }
    };

    listEle.addEventListener('scroll', infiniteHandler);
    listEle.addEventListener('touchmove', infiniteHandler);
  }
});
