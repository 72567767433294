document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('submit', (e) => {
    const form = e.target;
    const hasStripFormParams = form.hasAttribute('data-strip-form-params'); // removes the specified form params
    const hasStripBlankParams = form.hasAttribute('data-strip-blank-params'); // removes blank form params except for the specified form params

    if (!hasStripFormParams && !hasStripBlankParams) {
      return;
    }

    e.preventDefault();
    const formData = new FormData(form);
    const cleanedFormData = new FormData();

    if (form.hasAttribute('data-strip-form-params')) {
      const paramsToStrip = form.getAttribute('data-strip-form-params').split(',');
      paramsToStrip.forEach(param => formData.delete(param));
    }

    if (form.hasAttribute('data-strip-blank-params')) {
      const blankParamsToKeep = (form.getAttribute('data-allow-blank-params') ?? '').split(',');
      for (let [key, value] of formData.entries()) {
        if (value.trim() !== '' || blankParamsToKeep.includes(key)) {
          cleanedFormData.append(key, value);
        }
      }
    }

    const queryString = new URLSearchParams(cleanedFormData).toString();
    window.location.href = form.action + '?' + queryString;
  });
});
